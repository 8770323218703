import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    getLatLng,
    geocodeByAddress,
} from 'react-google-places-autocomplete';
import { getDictToParamsUrl, getGResult, getUrlParamsDict, setAlerts } from '../../AppUtils';
import { Navigate, useNavigate } from 'react-router-dom';
import GPlaceAutoComplete from '../Utils/GPlaceAutoComplete';
import { ReaSelect } from '../Utils/ReaSelect';
import { useGlobalState } from '../GlobalContext';


const Search = (props) => {
    
    const params = getUrlParamsDict();

    const opts = [
        { "label": "Pune", "value": "Pune" },
        { "label": "Mumbai", "value": "Mumbai" },
        { "label": "Thane", "value": "Thane" },
    ]

    const [gState, setGState] = useGlobalState();

    const [city, setCity] = useState('');
    const [center, setCenter] = useState({ lat: 18.52653, lng: 73.855532 });
    const [custom_lat, setLat] = useState("");
    const [custom_lng, setLng] = useState("");
    const [value, setValue] = useState();
    const [coord, setCoord] = useState({
        lat: 18.5653103,
        lng: 73.83751099999999,
    });
    const [nextTo, setNextTo] = useState(null);

    const navigate = useNavigate();

    const isAuthChecked = useRef(false);


    const onSubmit = useCallback((e = null, hmSearch = null) => {
        e?.preventDefault();
        if ((city && value && coord) || hmSearch || (custom_lat && custom_lng)) {
            try {
                let city_ = city;
                let latitude;
                let longitude;
                let loc_lbl;

                if (custom_lat && custom_lng) {
                    latitude = parseFloat(custom_lat);
                    longitude = parseFloat(custom_lng);
                    loc_lbl = custom_lat + ', ' + custom_lng;
                }

                if (value) {
                    latitude = coord.lat;
                    longitude = coord.lng;
                    loc_lbl = value.value.structured_formatting.main_text
                }

                if (gState?.hmSearch && gState?.hmSearch.searchFor === "Micromarket Analysis" && hmSearch) {
                    city_ = hmSearch.city;
                    latitude = hmSearch.coord.lat;
                    longitude = hmSearch.coord.lng;
                    loc_lbl = hmSearch.value.value.structured_formatting.main_text;
                } else if (gState?.hmSearch) {
                    setAlerts("error", 'Wrong request recived...');
                }

                const body = {
                    City: city_,
                    lng: longitude,
                    lat: latitude,
                    distance: 3,
                    basedOn: "igr",
                    loc_lbl: loc_lbl,
                };

                setGState({
                    hmSearch: null,
                    searchForMicroList: body,
                    resultForMicroList: null,
                });

                setNextTo(getDictToParamsUrl("/property-data-analytics/list/", body));

            } catch (err) {
                console.log(err.message);
            }

        }
    }, [city, coord, custom_lat, custom_lng, gState?.hmSearch, setGState, value]);



    // console.log("gState?.hmSearch: ", gState?.hmSearch);

    useEffect(() => {
        if (gState?.hmSearch && gState?.hmSearch.searchFor === "Micromarket Analysis") {
            onSubmit(null, gState?.hmSearch);
        } else if (gState?.hmSearch) {
            setAlerts("error", 'Wrong request recived...');
        }

        let foSep = document?.getElementById("id_footer_separator");
        foSep && !foSep?.classList?.contains("bg-secondary") && foSep?.classList?.add("bg-secondary");

        return () => {
            let foSep = document?.getElementById("id_footer_separator");
            foSep && foSep?.classList?.contains("bg-secondary") && foSep?.classList?.remove("bg-secondary");
        }

    }, [gState?.hmSearch, onSubmit]);



    useEffect(() => {
        if (value && !gState?.hmSearch) {
            geocodeByAddress(value.label).then((result) => {
                getLatLng(getGResult(result)).then((googleCoord) => setCoord(googleCoord));
            });
        }
    }, [gState?.hmSearch, value]);



    useEffect(() => {
        if (city) {
            switch (city) {
                case "Pune":
                    setCenter({ lat: 18.52653, lng: 73.855532 });
                    break;

                case "Mumbai":
                    setCenter({ lat: 19.1058712, lng: 72.8989012 });
                    break;

                case "Bangalore":
                    setCenter({ lat: 12.974072, lng: 77.57738 });
                    break;

                case "Thane":
                    setCenter({ lat: 19.218330, lng: 72.978088 });
                    break;

                default:
                    setCenter({ lat: 18.52653, lng: 73.855532 })
                    break;
            }
        }
    }, [city]);



    useEffect(() => {
        if (!isAuthChecked?.current && (gState?.isAuth !== true || gState?.isMmaAllowed !== true)) {
            setAlerts("error", "Property Data Analytics: Unauthorised Access...");
            isAuthChecked.current = true;
            navigate(gState?.isAuth !== true ? getDictToParamsUrl("/auth/signin/", { next: window?.location?.pathname, ...params }) : '/');
        };

    }, [gState?.isAuth, gState?.isMmaAllowed, navigate, params]);


    return (
        nextTo ? <Navigate to={nextTo} /> : <>
            <div className='row justify-content-center bg-secondary pt-5' >
                {/* <!-- Header and footer --> */}
                <div className="col-md-7 col-xl-4 my-auto card border border-3 text-center mb-5" style={{ borderRadius: "0.5rem" }}>
                    <div className="card-header">
                        <h4 className="card-title text-primary display-6 mb-0">Micromarket Analysis</h4>
                    </div>
                    <div className="card-body">
                        <div className="card-text">
                            <form id='id_search_form' method='POST' onSubmit={(ev) => onSubmit(ev)}>
                                <label className='mb-1'>
                                    <b>
                                        City
                                    </b>
                                </label>

                                <ReaSelect
                                    {...props}
                                    isDisabled={false}
                                    value={city && {
                                        "label": city,
                                        "value": city
                                    }}
                                    onChange={(val) => {
                                        setCity(val?.value);
                                    }}
                                    options={opts.sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0)}
                                    // isRequired={!(custom_lat && custom_lng)}

                                    border={true}
                                    maxHeight={`38px`}
                                    placeholder="Search or Select City..."

                                />


                                <label className='mb-1 mt-4'>
                                    <b>
                                        Location or Project
                                        <br />
                                        <small>
                                            * For which micromarket analysis or Data insights is required.
                                        </small>
                                    </b>
                                </label>
                                <GPlaceAutoComplete
                                    {...props}
                                    center={center}
                                    isDisabled={!city}
                                    value={value}
                                    onChange={(val) => {
                                        setValue(val);
                                    }}
                                    isRequired={!(custom_lat && custom_lng)}

                                    border={true}
                                    maxHeight={`38px`}
                                    placeholder="Type to search for a location or project..."

                                />

                                <label className='text-center my-3'><b>Or</b></label>
                                <div className="row justify-content-between">
                                    <div className='col'>
                                        <label className='mb-1'>
                                            <b>
                                                Latitude
                                            </b>
                                        </label><br />
                                        <input type='text' className='form-control form-control-sm mx-1' id='id_custom_lat' min={0} value={custom_lat} onChange={(ev) => setLat(ev.target.value)} placeholder='Enter Lattitude' required={value ? true : false} disabled={value ? true : false} />
                                    </div>
                                    <div className='col'>
                                        <label className='mb-1'>
                                            <b>
                                                Longitude
                                            </b>
                                        </label><br />
                                        <input type='text' className='form-control form-control-sm mx-1' id='id_custom_lng' min={0} value={custom_lng} onChange={(ev) => setLng(ev.target.value)} placeholder='Enter Longitude' required={value ? true : false} disabled={value ? true : false} />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <button type='submit' form='id_search_form' className="btn btn-primary" style={{ borderRadius: "0.5rem" }}>Get Project List</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Search