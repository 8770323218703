import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useGlobalState } from '../GlobalContext';

const GPlaceAutoComplete = (props) => {
    const [gState,] = useGlobalState();

    const darkFont = props.border && gState?.theme !== "dark";


    return (
        <>
            <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GMAP_KEY}
                libraries = {["places"]}
                autocompletionRequest={{
                    componentRestrictions: {
                        country: ["in"],
                    },
                    location: props.center,
                    radius: 50000,
                }}
                debounce={300}
                selectProps={{
                    isClearable: true,
                    required: "isRequired" in props ? props?.isRequired : true,
                    isDisabled: props.isDisabled,
                    value: props.value,
                    onChange: props.onChange,
                    classNames: {
                        
                        control: (state) => ((props.border ? "border " : "") + "text-sm"),
                        
                        input: (state) => ("text-sm"),
                        
                        singleValue: (state) => ("text-sm"),
                        
                        option: (state) => ("text-sm" + (state.isFocused ? " text-primary bg-primary bg-opacity-10" : "")),
                        
                        menu: (state) => ("text-sm border rounded"),
                        
                        placeholder: (state) => ("text-sm text-muted form-text my-auto"),

                    },
                    styles: {
                        
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: 'rgba(255, 255, 255, .25)',
                            backgroundColor: 'inherit',
                            color: 'white',
                            maxHeight: props.maxHeight,
                            borderRadius: "0.7rem",
                        }),

                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: state.isFocused ? "" : (gState?.theme === "dark" ? '#121519' : 'inherit'),
                            color: state.isFocused ? "" : (gState?.theme === "dark" ? 'white' : 'inherit'),
                            borderRadius: "0.5rem",
                        }),
                        
                        menu: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: gState?.theme === "dark" ? '#121519' : "white",
                            color: gState?.theme === "dark" ? 'white' : 'inherit',
                        }),

                        singleValue: (baseStyles, state) => ({
                            ...baseStyles,
                            color: darkFont ? "black" : 'white',
                            maxHeight: props.maxHeight,
                        }),

                        placeholder: (baseStyles, state) => ({
                            ...baseStyles,
                            color: darkFont ? "black" : 'white',
                            maxHeight: props.maxHeight,
                        }),

                        input: (provided, state) => ({
                            ...provided,
                            color: darkFont ? "black" : 'white',
                            maxHeight: props.maxHeight,
                        }),

                    },
                    placeholder: props.placeholder,
                }}
            />
        </>
    )
}

export default GPlaceAutoComplete;